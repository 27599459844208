@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'graphiql/graphiql.css';

:root {
  --tw-color-primary-50: 239 246 255;
  --tw-color-primary-100: 219 234 254;
  --tw-color-primary-200: 191 219 254;
  --tw-color-primary-300: 147 197 253;
  --tw-color-primary-400: 96 165 250;
  --tw-color-primary-500: 59 130 246;
  --tw-color-primary-600: 37 99 235;
  --tw-color-primary-700: 29 78 216;
  --tw-color-primary-800: 30 64 175;
  --tw-color-primary-900: 30 58 138;
  --tw-color-primary-950: 23 37 84;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #eff6ff */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #dbeafe */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #bfdbfe */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #93c5fd */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #60a5fa */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #3b82f6 */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #2563eb */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #1d4ed8 */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #1e40af */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #1e3a8a */
  --color-primary-950: rgb(var(--tw-color-primary-950)); /* #172554 */
}

@layer base {
  @font-face {
    font-family: 'Robot';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('/public/assets/fonts/Robot.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  .cursor-newtab {
    cursor: url('/assets/images/new-tab.png') 10 10, pointer;
  }

  /* #region  /**=========== Typography =========== */
  .h0 {
    @apply font-primary text-3xl font-bold md:text-5xl;
  }

  h1,
  .h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2,
  .h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3,
  .h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4,
  .h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  body,
  .p {
    @apply font-primary text-sm md:text-base;
  }
  /* #endregion  /**======== Typography =========== */

  .layout {
    min-width: 48rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }

  /* Truncate text */
  .truncate-3-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .truncate-10-lines {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(
        to right,
        var(--color-primary-400),
        var(--color-primary-500)
      );
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }
}

/* graphiql css cover */
#graphiql-box .graphiql-container {
  flex-direction: row-reverse;
}
#graphiql-box .graphiql-container .graphiql-main {
  flex-direction: row-reverse;
}

#graphiql-box .graphiql-execute-button {
  background-color: #127fff;
}

#graphiql-box .cm-s-graphiql .cm-keyword {
  color: #127fff;
}
#graphiql-box button.graphiql-execute-button:focus {
  outline: #285eef;
}

/** ========== Custom Tour Popover ========== */
.custom-tour-popover
  .custom-tour-popover-content
  .custom-tour-popover-description {
  padding: 0;
  border-radius: 8px;
}

.custom-tour-popover .custom-tour-popover-content .custom-tour-popover-footer {
  float: left;
  position: relative;
  left: 30px;
  bottom: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 304px;
  padding: 0;
  border-radius: 8px;
}

.custom-tour-popover
  .custom-tour-popover-content
  .custom-tour-popover-prev-btn {
  width: 106px;
  height: 40px;
}

.custom-tour-popover
  .custom-tour-popover-content
  .custom-tour-popover-next-btn {
  width: 106px;
  height: 40px;
}

@media screen and (min-width: 640px) {
  .custom-tour-popover .custom-tour-popover-content .custom-tour-popover-close {
    left: 740px;
  }
}

#notification .ant-table-thead > tr > th {
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  padding-left: 16px;
  color: #808080;
}
#notification .ant-table-tbody > tr {
  background-color: #f5f5f5;
}
#notification .ant-table-tbody > tr > td {
  padding-left: 16px;
  border: none;
}

.ant-slider .ant-slider-track {
  background-color: #127fff;
}
